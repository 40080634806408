<template>
  <div>
    <NavBar />
    <v-form @submit.prevent="savePerson">
      <v-container>
        <v-row>
          <v-col cols="4">
            <v-text-field outlined v-model="firstName" label="Όνομα"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="lastName" label="Επώνυμο"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="fatherName" label="Όνομα Πατέρα"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="origin" label="Προέλευση"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="editor" label="Συντάκτης"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-combobox :items="categories" item-text="name" item-value="id" label="Κατηγορία" multiple clearable outlined
              v-model="selectedCategory"></v-combobox>
          </v-col>
          <v-col cols="12">
            <v-textarea outlined v-model="property" label="Ιδιότητα / Θέση"></v-textarea>
          </v-col>          
          <v-col cols="12">
            <span style="color:gray">Βιογραφικά Στοιχεία</span>
            <quill-editor outlined v-model="bio" :options="editorOption" />
          </v-col>
          <v-col class="mt-10" cols="12">
            <span style="color:gray">Βιβλιογραφία</span>
            <quill-editor outlined v-model="bibliography" label="Κατηγορία" :options="editorOption" />
          </v-col>
        </v-row>
        <div class="mt-5" style="margin-bottom:5px;">
          <v-btn width="300px" style="margin-bottom:4%" type="submit" color="primary">
            Αποθηκευση
          </v-btn>
        </div>
        <v-snackbar v-model="snackbar" :color="snackbarColor" bottom top multi-line>
          <span class="white--text">{{ snackbarMessage }}</span>
        </v-snackbar>
      </v-container>
    </v-form>
  </div>
</template>
  
<script>
import NavBar from './NavBar.vue';
import axios from "axios"
import store from '../state/store'
import Swal from "sweetalert2";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components: { NavBar, quillEditor },
  data() {
    return {
      categories: [],
      firstName: "",
      lastName: "",
      fatherName: "",
      persons: [],
      selectedCategory: [],
      categoryName: "",
      editor: "",
      bio: "",
      origin: "",
      property: "",
      bibliography: "",
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{
              color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00',
                '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc',
                '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb',
                '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0',
                '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200',
                '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
                '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                '#c0c0c0', '#ffbfbf', '#ffffbf', '#bfff00', '#00ff00',
                '#00ffff', '#bf00ff', '#ffffff', '#e6b8af', '#ffc0cb',
                '#ffffc0', '#bfe6bf', '#c0c0ff', '#ffc0e6', '#ffffff']
            },
            {
              background: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00',
                '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc',
                '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb',
                '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0',
                '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200',
                '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
                '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                '#c0c0c0', '#ffbfbf', '#ffffbf', '#bfff00', '#00ff00',
                '#00ffff', '#bf00ff', '#ffffff', '#e6b8af', '#ffc0cb',
                '#ffffc0', '#bfe6bf', '#c0c0ff', '#ffc0e6', '#ffffff']
            }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"]
          ]
        },
      },
    };
  },
  mounted() {
    axios
      .get(axios.defaults.baseURL + "/api/categories")
      .then((response) => {
        this.categories = response.data;
        console.log(this.categories);
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to fetch categories");
      });
  },
  methods: {
    savePerson() {
      const selectedCategories = this.selectedCategory.map(category => {
        return {
          id: category.id,
          name: category.name
        }
      })

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        fatherName: this.fatherName,
        editor: this.editor,
        bio: this.bio,
        origin: this.origin,
        property: this.property,
        bibliography: this.bibliography,
        categories: selectedCategories
      }

      console.log(payload);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + store.state.user.token;
      axios
        .post(axios.defaults.baseURL + "/api/addPerson", payload)
        .then((response) => {
          console.log(response);
          Swal.fire({
            title: 'Επιτυχία',
            text: 'Η προσθήκη του ατόμου ολοκληρώθηκε με επιτυχία!',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            this.$router.push("/list");
          });
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add person!");
        });
    }
  },
};
</script>
<style scoped>
.container {
  width: 90%;
  margin-top: 1%;
  margin-bottom: 9%;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #000000 !important;
  color: #ffffff;
  text-align: right !important;
}
</style>
