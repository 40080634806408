<template>
    <div>
        <NavBar></NavBar>
        <div class="container">

            <v-row align="center" style="margin-bottom:3%">
                <v-col cols="auto">
                    <v-btn icon @click="goBack">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <h3 class="text-center">Στοιχεία Εγγραφής</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field outlined v-model="person.firstName" label="Όνομα"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined v-model="person.lastName" label="Επώνυμο"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined v-model="person.fatherName" label="Όνομα Πατέρα"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined v-model="person.origin" label="Προέλευση"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined v-model="person.editor" label="Συντάκτης"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-combobox :items="categories" item-text="name" item-value="id" label="Κατηγορία" multiple clearable
                        outlined v-model="selectedCategory"></v-combobox>
                </v-col>
                <v-col cols="12">                    
                    <v-textarea outlined v-model="person.property" label="Ιδιότητα / Θέση"></v-textarea>
                </v-col>                
                <v-col class="mt-10" cols="12">
                    <span style="color:gray">Βιογραφικά Στοιχεία</span>
                    <quill-editor outlined v-model="person.bio" :options="editorOption" />
                </v-col>
                <v-col class="mt-10" cols="12">
                    <span style="color:gray">Βιβλιογραφία</span>
                    <quill-editor outlined v-model="person.bibliography" label="Κατηγορία" :options="editorOption" />
                </v-col>
            </v-row>
            <div class="mt-5">
                <v-btn width="300px" style="margin-bottom:1%" type="submit" color="green"
                    @click="updatePerson">Αποθηκευση</v-btn>
            </div>
            <v-snackbar v-model="snackbar" :color="snackbarColor" bottom top multi-line>
                <span class="white--text">{{ snackbarMessage }}</span>
            </v-snackbar>
        </div>
    </div>
</template>
  
<script>
import NavBar from './NavBar.vue';
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import store from '../state/store'
import Swal from "sweetalert2";

import { quillEditor } from 'vue-quill-editor'

export default {
    components: { NavBar, quillEditor },
    data() {
        return {
            person: {
                firstName: "",
                lastName: "",
                fatherName: "",
                origin: "",
                editor: "",
                property: "",
                bio: "",
                bibliography: "",
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", false, "large", "huge"] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{
                            color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00',
                                '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc',
                                '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb',
                                '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0',
                                '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200',
                                '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
                                '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                                '#c0c0c0', '#ffbfbf', '#ffffbf', '#bfff00', '#00ff00',
                                '#00ffff', '#bf00ff', '#ffffff', '#e6b8af', '#ffc0cb',
                                '#ffffc0', '#bfe6bf', '#c0c0ff', '#ffc0e6', '#ffffff']
                        },
                        {
                            background: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00',
                                '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc',
                                '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb',
                                '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0',
                                '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200',
                                '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
                                '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                                '#c0c0c0', '#ffbfbf', '#ffffbf', '#bfff00', '#00ff00',
                                '#00ffff', '#bf00ff', '#ffffff', '#e6b8af', '#ffc0cb',
                                '#ffffc0', '#bfe6bf', '#c0c0ff', '#ffc0e6', '#ffffff']
                        }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ["clean"],
                        ["link", "image", "video"]
                    ]
                },
            },
            categories: [],
            snackbar: false,
            snackbarMessage: "",
            snackbarColor: ""
        };
    },
    computed: {
        selectedCategory: {
            get() {
                return this.person.categories.map(category => ({
                    name: category.name,
                    id: category.id
                }));
            },
            set(value) {
                this.person.categories = value.map(category => ({
                    name: category.name,
                    id: category.id
                }));
            }
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async updatePerson() {
            const personId = this.$route.params.id;
            let data = {
                firstName: this.person.firstName,
                lastName: this.person.lastName,
                fatherName: this.person.fatherName,
                origin: this.person.origin,
                editor: this.person.editor,
                property: this.person.property,
                categories: this.selectedCategory,
                bio: this.person.bio,
                bibliography: this.person.bibliography,
            };
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + store.state.user.token;
            axios
                .post(axios.defaults.baseURL + `/api/person/${personId}`, data)
                .then((response) => {
                    console.log(response.data);
                    Swal.fire({
                        title: "Επιτυχία",
                        text: "Η ενημέρωση του ατόμου πραγματοποιήθηκε επιτυχώς!",
                        icon: "success",
                        confirmButtonText: "OK",
                    }).then(() => {
                        this.$router.push("/view/" + personId);
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    mounted() {
        axios
            .get(axios.defaults.baseURL + "/api/categories")
            .then((response) => {
                this.categories = response.data;
                console.log(this.categories);
            })
            .catch((error) => {
                console.error(error);
                alert("Failed to fetch categories");
            });
    },
    created() {
        const personId = this.$route.params.id;
        axios
            .get(axios.defaults.baseURL + `/api/persons/${personId}`)
            .then(response => {
                this.person = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    }
};
</script>
<style scoped>
.container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 4%;
}</style>
  