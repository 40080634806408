<template>
  <div class="ma-4">
    <v-row>
      <NavBar></NavBar>
    </v-row>
    <div class="centered-container">
      <v-col cols="12">
        <quill-editor v-model="about" :options="editorOption" />
      </v-col>
      <!-- <v-data-table :items="tableData" hide-default-footer :headers="tableHeaders" style="margin-bottom:3%">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="index"
              :style="{ backgroundColor: index % 5 === 0 ? '#E5F1FF' : index % 5 === 1 ? '#F5F5F5' : index % 5 === 2 ? '#FFF9E5' : index % 5 === 3 ? '#F5E5FF' : '#E5FFE5' }">
              <td>{{ item.name }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.position }}</td>
              <td>{{ item.organization }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table> -->
      <div class="container2">
        <v-btn width="300px" style="margin-bottom:8%;" type="submit" color="green" @click="updateAbout">Αποθηκευση</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import store from "../state/store";
import { quillEditor } from 'vue-quill-editor'
import Swal from "sweetalert2";

export default {
  components: {
    NavBar, quillEditor
  },
  data() {
    return {
      about: '',
      alignment: "left",
      tableHeaders: [
        { text: 'Όνομα', value: 'name' },
        { text: 'Τίτλος', value: 'title' },
        { text: 'Θέση', value: 'position' },
        { text: 'Οργανισμός', value: 'organization' },
      ],
      tableData: [
        { name: 'Ιωάννης Ανδρόνικος', title: 'Εκπαιδευτικός (ΜΑ Ιστορίας)', position: 'Βοηθός ερευνητής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
        { name: 'Παναγιώτης Γέροντας', title: 'Υποπλοίαρχος ΠΝ – Υπηρεσία Ιστορίας Ναυτικού (ΜΑ Ιστορίας)', position: 'Βοηθός ερευνητής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
        { name: 'Ανδρέας Καστάνης', title: 'Καθηγητής Στρατιωτικής Ιστορίας ΣΣΕ', position: 'Ερευνητής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
        { name: 'Στέφανος Παπαγεωργίου', title: 'Ομότιμος καθηγητής', position: 'Διευθυντής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
        {
          name: 'Αναστάσιος Πολύχρονος', title: 'Υποστράτηγος, Δρ. Ιστορίας, διδάσκων Ειδικός Επιστήμονας στη Στρατιωτική Ιστορία στη ΣΣΕ', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ', position: 'Ερευνητής',
        }
      ],
      editorOption: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['clean'],
          ['link', 'image', 'video']
        ],
        formats: ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'list', 'bullet', 'script', 'indent', 'direction', 'color', 'background', 'align', 'link', 'image', 'video']
      }
    }
  },
  mounted() {
    axios
      .get(axios.defaults.baseURL + "/api/getAbout")
      .then((response) => {
        this.about = response.data.map((item) => item.text).join(", ");
        console.log(this.about);
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to fetch categories");
      });
  },
  methods: {
    updateAlignment(range) {
      const align = this.$refs.myQuillEditor.quill.getFormat(range).align;
      this.alignment = align || "left";
    },
    updateAbout() {
      let data = {
        text: this.about
      };
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + store.state.user.token;
      axios
        .post(axios.defaults.baseURL + `/api/about/1`, data)
        .then(response => {
          console.log(response.data);
          Swal.fire({
            title: 'Επιτυχία ενημέρωσης',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            this.$router.push("/about");
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.centered-container {
  margin-top: 1% !important;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 0 auto;
}

.title {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1.2rem !important;
  text-align: center;
  margin-bottom: -1rem;
  margin-top: 0.1rem;
  text-shadow: 1px 1px #CCCCCC;
}

.box {
  border: 1px solid black;
  padding: 10px;
}

.title1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 3.0em !important;
  text-align: center;
  margin-bottom: 0.5em;
  text-shadow: 1px 1px #CCCCCC;
}

h1,
h2,
h3 {
  text-align: center;
}

h2,
h3 {
  margin-top: 1.5em;
}

ul {
  list-style: disc;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  text-align: left;
}

li {
  margin-top: 0.5em;
}

.container2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  text-align: left;
  margin-top: 0;
  margin-bottom: 1.5em;
}



.even {
  background-color: #ffb7b7;
}

.odd {
  background-color: #d6f9a2;
}

@media (max-width: 767px) {
  .title {
    font-size: 2rem !important;
  }
}
</style>
