<template>
    <div>
        <NavBar></NavBar>
        <div class="container">

            <v-row align="center" style="margin-bottom:3%">
                <v-col cols="auto">
                    <v-btn icon @click="goBack">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <h3 class="text-center">Στοιχεία Εγγραφής</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field outlined readonly v-model="person.firstName" label="Όνομα"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined readonly v-model="person.lastName" label="Επώνυμο"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined readonly v-model="person.fatherName" label="Όνομα Πατέρα"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined readonly v-model="person.origin" label="Προέλευση"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field outlined readonly v-model="person.editor" label="Συντάκτης"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-combobox :items="categories" readonly item-text="name" item-value="id" label="Κατηγορία" multiple outlined
                        v-model="selectedCategory"></v-combobox>
                </v-col>
                <v-col cols="12">                    
                    <v-textarea outlined readonly v-model="person.property" label="Ιδιότητα / Θέση"></v-textarea>
                </v-col>                
                <v-col cols="12">
                    <span style="color:gray">Βιογραφικά Στοιχεία</span>
                    <div class="outlinedQuilly ql-editor" v-html="person.bio"></div>
                </v-col>
                <v-col class="mt-10" cols="12">
                    <span style="color:gray">Βιβλιογραφία</span>
                    <div class="outlinedQuilly ql-editor" v-html="person.bibliography"></div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
  
<script>
import NavBar from './NavBar.vue';
import axios from 'axios'
export default {
    components: { NavBar },
    data() {
        return {
            person: {
                firstName: "",
                lastName: "",
                fatherName: "",
                origin: "",
                editor: "",
                property: "",
                bio: "",
                bibliography: ""
            },
            categories: [],

        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    },
    computed: {
        formattedAbout() {
            const div = document.createElement('div');
            div.innerHTML = this.about;

            // Get the alignment from the Quill editor
            const alignment = this.$parent.$refs.myQuillEditor.quill.getFormat().align;

            // Set the style attribute with the correct alignment
            if (alignment === 'center') {
                div.querySelector('.ql-editor').setAttribute('style', 'text-align: center');
            } else if (alignment === 'right') {
                div.querySelector('.ql-editor').setAttribute('style', 'text-align: right');
            } else {
                div.querySelector('.ql-editor').setAttribute('style', 'text-align: left');
            }

            return div.innerHTML;
        },
        selectedCategory: {
            get() {
                return this.person.categories.map(category => ({
                    name: category.name,
                    id: category.id
                }));
            },
        }
    },
    created() {
        const personId = this.$route.params.id;
        axios
            .get(axios.defaults.baseURL + `/api/persons/${personId}`)
            .then((response) => {
                this.person = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>
<style scoped>
.container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom:12%;
}

.outlinedQuilly {
    outline: 1px solid rgb(0, 0, 0);
}
</style>
  