<template>
  <div class="">
    <v-app-bar app height="90" flat dark color="#81D4FA">
      <v-toolbar-items>
        <v-img
          @click="redirectToHome"
          class="ma-2 pr-5"
          :src="require('../assets/lexicon.png')"
          transition="scale-transition"
          max-width="250"
        />
      </v-toolbar-items>
      <v-spacer />

      <v-toolbar-items class="">
        <v-btn
          v-for="link in navLinks"
          :key="link.title"
          @click="forward(link.title)"
          plain
          class="secondary--text hidden-md-and-down"
          :ripple="false"
          text
        >
          {{ link.title }}
        </v-btn>
      </v-toolbar-items>

      

      <!--<div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              plain
              class="secondary--text hidden-md-and-down"
              :ripple="false"
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("language") }}
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item-group v-model="selectedLang" color="primary">
                <v-list-item
                  v-for="(country, index) in countries"
                  :key="index"
                  class="listItem"
                  @click="selectLanguage(country.tag)"
                >
                  <v-img
                    max-height="20%"
                    max-width="20%"
                    :src="country.image"
                  ></v-img>
                  <div class="paddingLeft">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="country.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </div>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
      </div>
-->
      <v-toolbar-items class="" v-if="loggedIn">
        <v-btn
          v-for="link in logoutLink"
          :key="link.title"
          @click="forward(link.title)"
          plain
          class="secondary--text hidden-md-and-down"
          :ripple="false"
          text
        >
          {{ link.title }}
        </v-btn>
      </v-toolbar-items>

      <v-menu :close-on-content-click="closeOnContentClick" :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            class="hidden-lg-and-up"
            v-if="!path"
          >
          </v-app-bar-nav-icon>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(link, index) in sideMenuLinks"
            :key="index"
            @click="forward(link.title)"
          >
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>        
         <!-- <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>{{ $t("language") }}</v-list-item-title>
            </template>
            <v-list>
              <v-list-item-group v-model="selectedLang" color="primary">
                <v-list-item
                  class="listFlag"
                  v-for="(country, index) in countries"
                  :key="index"
                  @click="selectLanguage(country.tag)"
                >
                  <v-img max-width="15%" :src="country.imageSm"></v-img>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-list-group>
-->
          <v-list-item
            v-for="(link, index) in logoutLink"
            :key="index"
            @click="forward(link.title)"
          >
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "navbar",
  data: () => ({
    closeOnContentClick: false,
    selectedLang: null,
    countries: [
      {
        name: "English",
        tag: "en",
        image: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
        imageSm:
          "https://www.printableworldflags.com/icon-flags/48/United%20Kingdom.png",
      },
      {
        name: "Ελληνικά",
        tag: "gr",
        image: "https://flagicons.lipis.dev/flags/4x3/gr.svg",
        imageSm: "https://www.printableworldflags.com/icon-flags/48/Greece.png",
      },
      // {
      //   name: "Deutsch",
      //   tag: "ge",
      //   image: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      // },
      // {
      //   name: "Latviski",
      //   tag: "la",
      //   image: "https://flagicons.lipis.dev/flags/4x3/lv.svg",
      // },
      // {
      //   name: "Rumänische",
      //   tag: "ro",
      //   image: "https://flagicons.lipis.dev/flags/4x3/ro.svg",
      // },
    ],
    adminOrGreek: false,
    navLinks: [],
    excelLinks: [],
    greekData: {},
    formLinks: [],
    dataLinks: [],
    logoutLink: [],
    false: false,
    dialog: false,
    path: false,
  }),

  methods: {
    ...mapActions("user", ["destroyToken"]),
    selectLanguage(tag, index) {
      this.selectedLang = index;
      this.$i18n.locale = tag;
      if (this.loggedIn) {
        this.navLinks = [{ title: this.$t("about") }, { title: this.$t("list") },];
        this.sideMenuLinks = [
        { title: this.$t("list") },
          { title: this.$t("about") },
        ];
        this.logoutLink = [{ title: this.$t("logout") }];
      } else {
        this.navLinks = [
        { title: this.$t("about1") },
          { title: this.$t("login") },
        ];
        this.sideMenuLinks = [
        { title: this.$t("about1") },
          { title: this.$t("login") },
        ];
      }

      this.dialog = this.false;
    },
    redirectToHome() {
      this.$router.push("/").catch(() => {});
    },
    logout() {
      {
        this.destroyToken().then(() => {
          this.$router.push("/login").catch(() => {});
        });
      }
    },

    forward(pageToMove) {
      if (pageToMove === this.$t("aboutEdit"))  {
        this.$router.push("/aboutEdit").catch(() => {});
        
      }
      else if (pageToMove === this.$t("about")) {
        this.$router.push("/about").catch(() => {});
      }
      else if (pageToMove === this.$t("list")) {
        this.$router.push("/list").catch(() => {});
      }
      else if (pageToMove === this.$t("eggrafes")) {
        this.$router.push("/eggrafes").catch(() => {});
      }
       else if (pageToMove === this.$t("logout")) {
        this.destroyToken().then(() => {
          this.$router.push("/login").catch(() => {});
        });

      } else if (pageToMove === this.$t("login")) {
        this.$router.push("/login").catch(() => {});
      }
      
    },
  },
  computed: {
    ...mapGetters("user", ["getUserRole", "loggedIn"]),
  },
  beforeMount() {
    if (this.$i18n.locale == "en") {
      this.selectedLang = 0;
    } else {
      this.selectedLang = 1;
    }
    if (window.location.href.match("landingPage")) {
      this.path = true;
    }
    if (this.loggedIn) {
      
      this.navLinks = [{ title: this.$t("aboutEdit") }, { title: this.$t("list") },];
      this.sideMenuLinks = [{ title: this.$t("aboutEdit") }, { title: this.$t("list") },];
      this.logoutLink = [{ title: this.$t("logout") }];
    } else {
      this.navLinks = [
      { title: this.$t("about") },
      { title: this.$t("eggrafes") },
      ];
      this.sideMenuLinks = [
      { title: this.$t("about") },
      { title: this.$t("eggrafes") },
      ];
    }
  },
};
</script>

<style scoped>
.listItem {
  padding-left: 16px !important;
  margin: 0 16px !important;
}
.v-menu__content {
  background: white;
}
.apexcharts-toolbar {
  z-index: 0;
}
.v-btn--active .v-btn__content {
  color: whitesmoke;
}
.btnContainer {
  align-self: center;
}
.paddingLeft {
  padding-left: 1rem;
}
.listItem {
  margin: 1rem;
}
</style>
