<template>
    <footer class="bg-light text-center text-lg-start">
      <div>
        <div class="text-center mt-2"><a href="http://lmids.sse.gr/" target="_blank">2019-2023 © Lab MIDS</a>, <a href="https://sse.army.gr" target="_blank"> Hellenic Army Academy</a>, <a href="https://keni.panteion.gr/index.php/el/" target="_blank"> ΚΕΝΙ </a></div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer',
  };
  </script>
  