import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/Login.vue";
import About from "@/components/About.vue";
import AboutEdit from "@/components/AboutEdit.vue";
import LandingPage from "../components/Views/LandingPage.vue";
import List from "@/components/List.vue"
import Add from "../components/Add.vue"
import View from "../components/View.vue"
import Edit from "../components/Edit.vue"
import store from "@/state/store.js";
import Eggrafes from "../components/Eggrafes.vue"
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/home",
  //   component: Home,
  //   name: "home",
  //   // meta: { requiresAuth: true },
  // },
  {
    path: "/landingPage",
    component: LandingPage,
    name: "landingPage",
  },
  {
    path: "/login",
    component: Login,
    name: "login",
  },
  // {
  //   path: "/dashboard",
  //   component: Dashboard,
  //   name: "Dashboard",
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      if (store.getters["user/loggedIn"]) {
        next({ name: 'list' });
      } else {
        next({ name: 'about' });
      }
    }
  },
  {
    path: "/about",
    component: About,
    name: "about",
  },
  {
    path: "/aboutEdit",
    component: AboutEdit,
    name: "aboutEdit",
    meta: { requiresAuth: true },
  },
  {
    path: "/list",
    component: List,
    name: "list",
    meta: { requiresAuth: true },
  },
  {
    path: "/eggrafes",
    component: Eggrafes,
    name: "eggrafes",
  },
  {
    path: "/add",
    component: Add,
    name: "add",
    meta: { requiresAuth: true },
  },
  {
    path: '/view/:id',
    component: View,
    name: 'view',
  },
  {
    path: '/edit/:id',
    component: Edit,
    name: 'edit',
    meta: { requiresAuth: true },
  },
  {
    path: "/view",
    component: View,
    name: "view",
    meta: { requiresAuth: true },
  },
  {
    path: "/edit",
    component: Edit,
    name: "edit",
    meta: { requiresAuth: true },
  },
  
];

const router = new VueRouter({
  mode: "history",
  routes,
  base: "/",
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["user/loggedIn"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresVisitor)) {
//     if (store.getters["user/loggedIn"]) {
//       next({
//         path: "/home",
//       });
//     } else next();
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!store.getters["user/loggedIn"]) {
//       next({
//         path: "/login",
//       });
//     } else next();
//   } else {
//     next({
//       path: "/home",
//     });
//   }
// });

// router.afterEach((to) => {
//   if (to.meta && to.meta.bgImage) {
//     document.body.style.backgroundRepeat = "no-repeat";
//     document.body.style.backgroundPosition = "center";
//     document.body.style.backgroundImage = `url(${to.meta.bgImage})`;
//   } else {
//     document.body.style.backgroundRepeat = "";
//     document.body.style.backgroundPosition = "";
//     document.body.style.backgroundImage = "";
//   }
// });

export default router;
