import store from "@/state/store.js";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/index.js";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import VueI18n from "vue-i18n";
import i18n from "./i18n";
import VueSimpleAlert from "vue-simple-alert";
import CKEditor from '@ckeditor/ckeditor5-vue2';


Vue.config.productionTip = false;

//  axios.defaults.baseURL = "http://localhost:8081";
// axios.defaults.baseURL = "http://spot.infili.com:8035";
axios.defaults.baseURL = "http://62.217.124.200:8081";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const {
      response: { status },
    } = err;

    if (status === 401) {
      store.dispatch("user/destroyToken");
      router.push("/login").catch(() => {});
    }
  }
);
Vue.use( CKEditor );
Vue.use(VueApexCharts);
Vue.use(VueI18n);
Vue.use(VueSimpleAlert);
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
